import React from 'react';
import { UnacceptableValueException } from '@hiwaldo/sdk/exceptions';
import { FooterStyles, FooterTemplateProps } from '../../..';
import {
  FooterCondensedFragment,
  FooterCookiePolicyFragment,
} from '../fragments';

export const FooterTemplateCondensed = (props: FooterTemplateProps) => {
  if (!props.cookiePolicyCloseHandler) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing cookie policy close handler'
    );
  }

  return (
    <FooterStyles
      data-cy="footer-partial"
      id={props.id}
      className={props.classes}
    >
      <FooterCondensedFragment
        companyLinks={props.companyLinks}
        socialLinks={props.socialLinks}
      />

      <FooterCookiePolicyFragment
        cookiePolicyAccepted={props.cookiePolicyAccepted}
        cookiePolicyCloseHandler={props.cookiePolicyCloseHandler}
        cookiePolicyLinkHandler={props.companyLinks.cookiePolicy.onLinkHandler}
      />
    </FooterStyles>
  );
};
