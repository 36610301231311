import { css } from 'styled-components';
import { StyledTheme, responsive } from '../../../../styles';

export const FooterBaseStyles = css<{ theme: StyledTheme }>`
  .footer-default-content-container {
    width: 100%;
    padding: 0 0 2rem;
    background-color: ${({ theme }) => theme.palette.primary.color};
    color: ${({ theme }) => theme.palette.primaryBackground.color};

    .footer-default-content-titlebar-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-bottom: 1px solid
        ${({ theme }) => theme.palette.primaryBackground.color};

      .footer-default-content-titlebar {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
        padding: 1.5rem 0.5rem 1.75rem;

        ${responsive.desktop.small(css`
          align-items: center;
          justify-content: flex-start;
          padding: 1.75rem 1rem;
        `)}

        > div {
          padding: 0;

          ${responsive.mobile.large(css`
            width: 50%;
          `)}
        }

        .footer-default-content-titlebar-logo {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 0 0 0 1rem;
          font-size: 1.15rem;

          ${responsive.desktop.small(css`
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 375px;
            padding: 0;
          `)}

          img {
            width: 150px;
            margin: 0;
            padding: 0.5rem 0 1rem 0;

            ${responsive.desktop.small(css`
              padding: 0;
            `)}
          }

          > div {
            width: 180px;

            ${responsive.mobile.large(css`
              font-size: 1.15rem;
            `)}

            ${responsive.desktop.small(css`
              text-align: right;
              width: 200px;
            `)}
          }
        }

        .footer-default-content-titlebar-region {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          padding: 0 1rem;
        }

        ${responsive.desktop.small(css`
          .footer-default-content-titlebar-region {
            display: none;
          }
        `)}
      }
    }

    .footer-default-content {
      display: flex;
      flex-direction: column;
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;
      padding: 1.5rem 0.5rem 0;

      ${responsive.desktop.small(css`
        flex-direction: row;
        padding: 1.5rem 1rem;
      `)}

      .footer-default-content-newsletter {
        width: 100%;
        padding: 0 1rem;

        ${responsive.desktop.small(css`
          width: 33%;
          padding: 0 5rem 0 0;
        `)}

        > span {
          font-size: 1.15rem;
        }

        .footer-default-content-newsletter-input {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-top: 1rem;
        }

        .footer-default-content-newsletter-complete {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-top: 1rem;
          font-size: 3.5rem;
          font-family: ${({ theme }) => theme.typography.mainFontFamily};

          .footer-default-content-newsletter-thankyou {
            margin-left: 1rem;
            font-size: 1.1rem;
          }
        }
      }

      .footer-default-content-links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 1rem;

        ${responsive.mobile.large(css`
          margin-top: 1.5rem;
        `)}

        ${responsive.desktop.small(css`
          width: 34%;
          padding: 0 0 0 4rem;
        `)}

        > div {
          width: 50%;
          font-size: 1.15rem;
          margin: 0.25rem 0;

          ${responsive.desktop.small(css`
            margin: 0.5rem 0;
          `)}

          span {
            cursor: pointer;

            &:hover {
              color: ${({ theme }) => theme.palette.primaryButton.color};
            }
          }
        }
      }

      .footer-default-content-payments {
        width: 100%;

        ${responsive.desktop.small(css`
          width: 33%;
        `)}

        .footer-default-content-region {
          padding: 0;
          margin-bottom: 0;

          ${responsive.desktop.small(css`
            padding: 0 0 0 10rem;
            margin-bottom: 2rem;
          `)}
        }

        ${responsive.mobile.large(css`
          .footer-default-content-region {
            display: none;
          }
        `)}

        .footer-default-content-payment-logos {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 0 1rem;

          ${responsive.mobile.large(css`
            margin-top: 1.5rem;
            justify-content: space-between;
          `)}

          ${responsive.desktop.small(css`
            padding: 0 0 0 10rem;
          `)}

          > div {
            width: 15%;
            margin-bottom: 0;

            ${responsive.desktop.small(css`
              width: 33%;
              margin-bottom: 1.5rem;
            `)}
          }

          .payment-logo-paypal {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            ${responsive.desktop.small(css`
              justify-content: flex-start;
            `)}

            img {
              width: 55px;

              ${responsive.desktop.small(css`
                width: 75px;
              `)}
            }
          }

          .payment-logo-mastercard {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            img {
              width: 25px;

              ${responsive.desktop.small(css`
                width: 35px;
              `)}
            }
          }

          .payment-logo-visa {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            ${responsive.desktop.small(css`
              justify-content: flex-end;
            `)}

            img {
              width: 45px;

              ${responsive.desktop.small(css`
                width: 60px;
              `)}
            }
          }

          .payment-logo-apple-pay {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            ${responsive.desktop.small(css`
              justify-content: flex-start;
            `)}

            img {
              width: 45px;

              ${responsive.desktop.small(css`
                width: 60px;
              `)}
            }
          }

          .payment-logo-amex {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            img {
              width: 45px;

              ${responsive.desktop.small(css`
                width: 60px;
              `)}
            }
          }

          .payment-logo-google-pay {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            ${responsive.desktop.small(css`
              justify-content: flex-end;
            `)}

            img {
              width: 45px;

              ${responsive.desktop.small(css`
                width: 60px;
                justify-content: flex-end;
              `)}
            }
          }
        }
      }
    }
  }

  .footer-default-condensed-container {
    width: 100%;
    background-color: ${({ theme }) => theme.palette.secondary.color};
    color: ${({ theme }) => theme.palette.primaryBackground.color};

    .footer-condensed-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: center;
      max-width: 1400px;
      margin: 0 auto;
      padding: 1.5rem 0 0.5rem;

      > div {
        width: 100%;
        max-width: 1400px;
        padding: 0 0 0.5rem 0;
        text-align: center;

        span {
          cursor: pointer;
          padding: 0 0.25rem;
        }

        img {
          cursor: pointer;
          width: 2.5rem;
        }
      }

      .footer-condensed-social {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
      }

      ${responsive.desktop.small(css`
        flex-direction: row;
        justify-content: space-between;
        padding: 0.75rem 1rem 0.25rem;

        .footer-condensed-copyright {
          flex-basis: 45%;
          flex-grow: 1;
          align-items: center;
          padding: 0.75rem 0 0 0;
          text-align: left;
        }

        .footer-condensed-links {
          flex-grow: 2;
          padding-top: 0.75rem;
          text-align: center;
        }

        .footer-condensed-social {
          flex-basis: 45%;
          display: flex;
          flex-direction: row;
          align-content: right;
          justify-content: right;
          padding-right: 0rem;
        }
      `)}
    }
  }

  .footer-cookie-policy-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    left: 0;
    z-index: 1000;
    width: 100%;
    padding: 0.5rem;
    background-color: ${({ theme }) => theme.palette.primaryBackground.color};
    color: ${({ theme }) => theme.palette.primary.color};

    ${responsive.desktop.small(css`
      justify-content: center;
      gap: 0.5rem;

      .footer-mobile-cookie-policy-message {
        display: none;
      }
    `)}

    ${responsive.mobile.large(css`
      .footer-desktop-cookie-policy-message {
        display: none;
      }
    `)}

    .footer-cookie-policy-bold {
      font-family: ${({ theme }) => theme.typography.mainFontFamilyBold};
    }

    .footer-cookie-policy-close {
      margin-top: -0.1rem;
      font-size: 1.25rem;
      cursor: pointer;
    }
  }
`;
