import React, { Fragment } from 'react';
import { RouteCompanyLinks, RouteSocialLinks } from '../../../../hooks';

export const FooterCondensedFragment = (props: {
  companyLinks: RouteCompanyLinks;
  socialLinks: RouteSocialLinks;
}) => {
  return (
    <div className="footer-default-condensed-container">
      <div className="footer-condensed-content">
        <div className="footer-condensed-copyright">
          &copy; WALDO 2023 All rights reserved
        </div>
        <div className="footer-condensed-links">
          <Fragment key={0}>
            <span
              className="footer-condensed-company-link"
              onClick={() => props.companyLinks.privacyPolicy.onLinkHandler()}
              dangerouslySetInnerHTML={{
                __html: props.companyLinks.privacyPolicy.label,
              }}
            ></span>
          </Fragment>
          <Fragment key={1}>
            <span dangerouslySetInnerHTML={{ __html: '&nbsp;|&nbsp;' }}></span>
            <span
              className="footer-condensed-company-link"
              onClick={() => props.companyLinks.cookiePolicy.onLinkHandler()}
              dangerouslySetInnerHTML={{
                __html: props.companyLinks.cookiePolicy.label,
              }}
            ></span>
          </Fragment>
          <Fragment key={2}>
            <span dangerouslySetInnerHTML={{ __html: '&nbsp;|&nbsp;' }}></span>
            <span
              className="footer-condensed-company-link"
              onClick={() => props.companyLinks.termsConditions.onLinkHandler()}
              dangerouslySetInnerHTML={{
                __html: props.companyLinks.termsConditions.label,
              }}
            ></span>
          </Fragment>
        </div>
        <div className="footer-condensed-social">
          <div
            className="footer-condensed-social-link"
            key={0}
            onClick={() => props.socialLinks.instagram.onLinkHandler()}
          >
            <img
              src={props.socialLinks.instagram.image}
              alt={props.socialLinks.instagram.alt}
            />
          </div>
          <div
            className="footer-condensed-social-link"
            key={1}
            onClick={() => props.socialLinks.facebook.onLinkHandler()}
          >
            <img
              src={props.socialLinks.facebook.image}
              alt={props.socialLinks.facebook.alt}
            />
          </div>
          <div
            className="footer-condensed-social-link"
            key={2}
            onClick={() => props.socialLinks.twitter.onLinkHandler()}
          >
            <img
              src={props.socialLinks.twitter.image}
              alt={props.socialLinks.twitter.alt}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
