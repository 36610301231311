import {
  FooterProps,
  FooterTemplateProps,
  FooterTemplateCondensed,
  FooterTemplateDefault,
  useFooterEffect,
} from '../../..';

export const FooterPartial = (props: FooterProps) => {
  const [state] = useFooterEffect(props);

  const templateProps: FooterTemplateProps = {
    id: props.id,
    display: props.display,
    companyLinks: props.companyLinks,
    socialLinks: props.socialLinks,
    footerLinks: props.footerLinks,
    regionDropdown: props.regionDropdown,
    newsletterInputProps: props.newsletterInputProps,
    submitInputProps: props.submitInputProps,
    newsletterComplete: props.newsletterComplete,
    cookiePolicyAccepted: props.cookiePolicyAccepted,
    cookiePolicyCloseHandler: props.cookiePolicyCloseHandler,
    ...state,
  };

  let template;
  switch (props.template) {
    case 'footerCondensed':
      template = FooterTemplateCondensed(templateProps);
      break;
    case 'footerDefault':
    default:
      template = FooterTemplateDefault(templateProps);
      break;
  }

  return template;
};
