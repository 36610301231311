import { FooterProps, FooterState } from '../../..';

export function useFooterEffect(props: FooterProps): [FooterState] {
  const { styleClasses } = props;

  const classes = styleClasses.join(' ');

  const state: FooterState = { classes };

  return [state];
}
