import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

export const FooterCookiePolicyFragment = (props: {
  cookiePolicyAccepted?: boolean;
  cookiePolicyLinkHandler: () => void;
  cookiePolicyCloseHandler: () => void;
}) => {
  return (
    <AnimatePresence>
      {!props.cookiePolicyAccepted && (
        <motion.div
          className="footer-cookie-policy-container"
          animate={{ bottom: '0rem' }}
          initial={{ bottom: '-4rem' }}
          exit={{ bottom: '-4rem' }}
          transition={{
            type: 'tween',
            ease: 'easeInOut',
            duration: 0.3,
          }}
        >
          <div className="footer-desktop-cookie-policy-message">
            By using WALDO’s services you agree to our{' '}
            <span
              className="footer-cookie-policy-bold"
              onClick={() => props.cookiePolicyLinkHandler()}
            >
              cookies policy
            </span>
            . We and our partners operate globally and use cookies, including
            for analytics, personalisation, and ads.
          </div>
          <div
            className="footer-mobile-cookie-policy-message"
            onClick={() => props.cookiePolicyLinkHandler()}
          >
            By using WALDO’s services you agree to our{' '}
            <span className="footer-cookie-policy-bold">cookies policy</span>.
          </div>
          <div
            className="footer-cookie-policy-close"
            onClick={() => props.cookiePolicyCloseHandler()}
          >
            x
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
